import omit from "lodash/omit";
import defaultsDeep from "lodash/defaultsDeep";
import dotProp from "dot-prop";

import json from "../assets/info.json";

const displayCanvas = document.getElementById("canvas-1");
const downloadCanvas = document.getElementById("download-canvas");
const controlPanel = document.getElementById("control");
const controlMain = document.getElementById("control-main");
const controlLayer = document.getElementById("control-layer");

let props = {
  occurrence: 1,
  // blendMode: "source-over",
};

const randomlyPickOne = (arr) => {
  const randomIndex = Math.floor(Math.random() * arr.length);
  return arr[randomIndex];
};

const loadImage = async (path) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function () {
      console.log("image loaded: ", img.src);
      resolve(img);
    };
    img.src = path;
  });
};

const addLayer = async (canvas, info, randomSeed = Math.random()) => {
  const ctx = canvas.getContext("2d");
  const imageName = randomlyPickOne(info.images);
  console.log("imageName: ", imageName, info.images);
  const img = await loadImage(`/assets/${imageName}`);
  if (info) {
    if (info.occurrence < randomSeed) {
      return;
    }
    if (info.opacity) {
      ctx.globalAlpha = info.opacity;
    }
    if (info.blendMode) {
      ctx.globalCompositeOperation = info.blendMode;
    }
  }
  let wRatio = canvas.width / img.width;
  let hRatio = canvas.height / img.height;
  let ratio = Math.min(wRatio, hRatio);
  let centerShift_x = (canvas.width - img.width * ratio) / 2;
  let centerShift_y = (canvas.height - img.height * ratio) / 2;
  ctx.drawImage(
    img,
    0,
    0,
    img.width,
    img.height,
    centerShift_x,
    centerShift_y,
    img.width * ratio,
    img.height * ratio
  );
  ctx.globalAlpha = 1;
  ctx.globalCompositeOperation = "source-over";
};

const updateCanvas = async () => {
  const displayCtx = displayCanvas.getContext("2d");
  displayCtx.clearRect(0, 0, displayCanvas.width, displayCanvas.height);
  const downloadCtx = downloadCanvas.getContext("2d");
  downloadCtx.clearRect(0, 0, downloadCanvas.width, downloadCanvas.height);

  console.log("updateCanvas - props: ", props);
  const layers = sortLayers(json);
  console.log("info: ", layers);
  for (let i = 0; i < layers.length; i++) {
    const { name, info } = layers[i];
    const updatedInfo = { ...info, ...props };
    const randomSeed = Math.random();
    console.log("udpated: ", updatedInfo);
    await addLayer(displayCanvas, updatedInfo, randomSeed);
    await addLayer(downloadCanvas, updatedInfo, randomSeed);
  }
};

const addLayerControl = (name, info) => {
  const container = document.createElement("div");
  container.className = "control-item";
  const title = document.createElement("p");
  title.innerText = name;
  container.appendChild(title);
  const controlElement = omit(info, ["images"]);
  console.log("controlElement: ", controlElement, name, info);
  Object.keys(controlElement).forEach((key, idx) => {
    const wrapper = document.createElement("div");
    wrapper.className = "control-input";
    const label = document.createElement("label");
    label.innerText = key;
    const input = document.createElement("input");
    switch (key) {
      case "blendMode":
        input.type = "text";
        break;
      case "opacity":
      case "occurrence":
        input.min = 0;
        input.max = 1;
        input.type = "number";
        break;
      default:
        input.type = "number";
    }
    input.value = controlElement[key];
    wrapper.appendChild(label);
    wrapper.appendChild(input);
    container.appendChild(wrapper);
  });

  controlLayer.appendChild(container);
};

const downloadImage = async () => {
  const link = document.createElement("a");
  link.download = "image.png";
  link.href = downloadCanvas.toDataURL();
  link.click();
};

const sortLayers = (arr) => {
  return arr.sort(
    (a, b) =>
      dotProp.get(a, `info.ordering`, -1) - dotProp.get(b, `info.ordering`, -1)
  );
};

const addMainControl = () => {
  const container = document.createElement("div");
  container.className = "control-item";
  const title = document.createElement("p");
  title.innerText = "Main Control";
  container.appendChild(title);
  Object.keys(props).forEach((key, idx) => {
    const wrapper = document.createElement("div");
    wrapper.className = "control-input";
    const label = document.createElement("label");
    label.innerText = key;
    const input = document.createElement("input");
    input.name = key;
    switch (key) {
      case "blendMode":
        input.type = "text";
        break;
      case "opacity":
      case "occurence":
        input.min = 0;
        input.max = 1;
        input.type = "number";
        break;
      default:
        input.type = "number";
    }
    input.value = props[key];
    input.onchange = (e) => {
      props[e.target.name] =
        e.target.type === "number" ? Number(e.target.value) : e.target.value;
      console.log("onChange: ", e.target.value);
    };
    wrapper.appendChild(label);
    wrapper.appendChild(input);
    container.appendChild(wrapper);
  });
  controlMain.appendChild(container);
};

const setupDisplayCanvas = () => {
  const displayCtx = displayCanvas.getContext("2d");
  displayCtx.fillStyle = "grey";
  displayCtx.fillRect(0, 0, displayCanvas.width, displayCanvas.height);
};

const init = async () => {
  setupDisplayCanvas();
  const layers = sortLayers(json);
  console.log("info: ", layers);
  for (let i = 0; i < layers.length; i++) {
    const { name, info } = layers[i];
    const updatedInfo = { ...props, ...info };
    console.log("init info: ", updatedInfo);
    const randomSeed = Math.random();
    await addLayer(displayCanvas, updatedInfo, randomSeed);
    await addLayer(downloadCanvas, updatedInfo, randomSeed);
    // addLayerControl(name, updatedInfo);
  }

  // add main control
  addMainControl();

  // add update button
  const button = document.createElement("button");
  button.onclick = updateCanvas;
  button.innerText = "Update Canvas";
  controlPanel.appendChild(button);

  // add download button
  const downloadBtn = document.createElement("button");
  downloadBtn.onclick = downloadImage;
  downloadBtn.innerText = "Download Image";
  controlPanel.appendChild(downloadBtn);
};

init();
